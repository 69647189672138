<template>
  <div class="menu-bar">
    <transition name="slide-up">
      <div :class="['menu-wrapper', {'hide-box-shadow': !menuVisible || settingVisible >= 0}]" v-show="menuVisible">
        <div class="icon-wrapper">
          <div class="icon-menu" @click="showSetting(3)"></div>
        </div>
        <div class="icon-wrapper">
          <div class="icon-progress" @click="showSetting(2)"></div>
        </div>
        <div class="icon-wrapper">
          <div class="icon-bright" @click="showSetting(1)"></div>
        </div>
        <div class="icon-wrapper">
          <div class="icon-A" @click="showSetting(0)"></div>
        </div>
      </div>
    </transition>
    <ebook-setting-font></ebook-setting-font>
    <ebook-setting-theme></ebook-setting-theme>
    <ebook-setting-font-popup></ebook-setting-font-popup>
    <ebook-setting-progress></ebook-setting-progress>
    <ebook-slide></ebook-slide>
  </div>
</template>

<script>
// import ContentView from '@/components/Content'
import EbookSettingFont from '@/components/ebook/EbookSettingFont'
import EbookSettingTheme from '@/components/ebook/EbookSettingTheme'
import EbookSettingFontPopup from '@/components/ebook/EbookSettingFontPopup'
import EbookSettingProgress from '@/components/ebook/EbookSettingProgress'
import EbookSlide from '@/components/ebook/EbookSlide'

import { ebookMixin } from '@/utils/mixin'
export default {
  name: 'MenuBar',
  mixins: [ebookMixin],
  components: {
    // ContentView
    EbookSettingFont,
    EbookSettingFontPopup,
    EbookSettingTheme,
    EbookSettingProgress,
    EbookSlide
  },
  props: {
    ifTitleAndMenuShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ifSettingShow: false,
      showTag: 0,
      ifShowContent: false
    }
  },
  methods: {
    showSetting(tagIndex) {
      this.setSettingVisible(tagIndex)
    },
    hideSetting() {
      this.ifSettingShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/global';
.menu-bar {
  .menu-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 200;
      display: flex;
      width: 100%;
      height: px2rem(48);
      background-color: white;
      box-shadow: 0 px2rem(-8) px2rem(8) rgba($color: #000000, $alpha: .15);
      font-size: px2rem(22);
      &.hide-box-shadow {
        box-shadow: none;
      }
      .icon-wrapper {
        flex: 1;
        @include center;
        .icon-progress {
          font-size: px2rem(28);
        }
        .icon-bright {
          font-size: px2rem(24);
        }
      }
  }
  .content-mask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
    display: flex;
    width: 100%;
    height: 100%;
    background: rgba(51,51,51,.8);
  }
}
</style>
