<template>
  <div>
    <transition name="slide-down">
      <div class="title-wrapper" v-show="menuVisible">
      <div class="left">
        <span class="icon-back" @click="back"></span>
      </div>
      <div class="right">
        <div class="icon-wrapper">
          <span class="icon-shelf" @click="showBookShelf"></span>
        </div>
        <div class="icon-wrapper">
          <span class="icon-cart"></span>
        </div>
        <div class="icon-wrapper">
          <span class="icon-more"></span>
        </div>
      </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ebookMixin } from '@/utils/mixin'

export default {
  name: 'TitleBar',
  props: {
    ifTitleAndMenuShow: Boolean
  },
  mixins: [ebookMixin],
  methods: {
    showBookShelf() {
        this.$router.push('/store/shelf')
    },
    back() {
      // console.log(this.$router)
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/global';
  .title-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 300;
    display: flex;
    width: 100%;
    background: white;
    height: px2rem(48);
    box-shadow: 0 px2rem(8) px2rem(8) rgba($color: #000000, $alpha: .15);
    font-size: px2rem(22);
    .left {
      flex: 0 0 px2rem(37);
       @include center;
       margin-left: px2rem(5);
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .icon-wrapper {
        flex: 0 0 px2rem(37);
        display: flex;
        @include center;
      }
    }
  }
</style>
