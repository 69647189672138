<template>
  <div class="ebook" ref="ebook">
    <ebook-header></ebook-header>
    <ebook-title></ebook-title>
    <ebook-reader></ebook-reader>
    <ebook-menu></ebook-menu>
    <ebook-bookmark></ebook-bookmark>
    <ebook-footer></ebook-footer>
  </div>
</template>

<script>
import EbookReader from '@/components/ebook/EbookReader'
import EbookTitle from '@/components/ebook/EbookTitle'
import EbookMenu from '@/components/ebook/EbookMenu'
import EbookBookmark from '@/components/ebook/EbookBookmark'
import EbookHeader from '@/components/ebook/EbookHeader'
import EbookFooter from '@/components/ebook/EbookFooter'

import { ebookMixin } from '@/utils/mixin'
import { getReadTime, saveReadTime } from '@/utils/localStorage'
export default {
  name: 'index',
  mixins: [ebookMixin],
  components: {
    EbookReader,
    EbookTitle,
    EbookMenu,
    EbookBookmark,
    EbookHeader,
    EbookFooter
  },
  watch: {
    offsetY(move) {
      if (!this.menuVisible && this.bookAvailable) {
        if (move > 0) {
          this.move(move)
        } else if (move === 0) {
          this.reset()
        }
      }
    }
  },
  methods: {
    move(move) {
      this.$refs.ebook.style.top = move + 'px'
      this.$refs.ebook.style.transition = 'all .2s linear'
    },
    reset() {
      this.$refs.ebook.style.top = 0
      setTimeout(() => {
        this.$refs.ebook.style.transition = ''
      }, 200)
    },
    startLoopReadTime() {
      let readTime = getReadTime(this.fileName)
      if (!readTime) {
        readTime = 0
      }
      this.task = setInterval(() => {
        readTime++
        if (readTime % 30 === 0) {
          saveReadTime(this.fileName, readTime)
        }
      }, 1000)
    }
  },
  mounted() {
    this.startLoopReadTime()
  },
  beforeDestroy() {
    if (this.task) {
      clearInterval(this.task)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/global.scss";
  .ebook {
    position: absolute;
    width: 100%;
    height:100%;
    top: 0;
    left: 0;
  }
</style>
