import { render, staticRenderFns } from "./EbookSlide.vue?vue&type=template&id=50e57478&scoped=true&"
import script from "./EbookSlide.vue?vue&type=script&lang=js&"
export * from "./EbookSlide.vue?vue&type=script&lang=js&"
import style0 from "./EbookSlide.vue?vue&type=style&index=0&id=50e57478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e57478",
  null
  
)

export default component.exports